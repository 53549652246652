// Generated by Framer (013b13c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["yLlubh3uH", "luKrhnor9"];

const serializationHash = "framer-5dfDr"

const variantClassNames = {luKrhnor9: "framer-v-nuzcrt", yLlubh3uH: "framer-v-di1udv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {closed: "yLlubh3uH", open: "luKrhnor9"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yLlubh3uH", W41POyFF1: click ?? props.W41POyFF1} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, W41POyFF1, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yLlubh3uH", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1nbbssn = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("luKrhnor9")
})

const onTapq7re41 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("yLlubh3uH")
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const isDisplayed = () => {
if (baseVariant === "luKrhnor9") return false
return true
}

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-di1udv", className, classNames)} data-framer-name={"closed"} data-highlight layoutDependency={layoutDependency} layoutId={"yLlubh3uH"} onTap={onTap1nbbssn} ref={refBinding} style={{...style}} {...addPropertyOverrides({luKrhnor9: {"data-framer-name": "open", onTap: onTapq7re41}}, baseVariant, gestureVariant)}><motion.div className={"framer-33hnex"} data-framer-name={"line3"} layoutDependency={layoutDependency} layoutId={"YcqE5gjxa"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{luKrhnor9: {rotate: 45}}}/>{isDisplayed() && (<motion.div className={"framer-1nseu5l"} data-framer-name={"line2"} layoutDependency={layoutDependency} layoutId={"oIWIgjD9B"} style={{backgroundColor: "rgb(255, 255, 255)"}}/>)}<motion.div className={"framer-12iifpy"} data-framer-name={"line 1"} layoutDependency={layoutDependency} layoutId={"tOlYQrR18"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{luKrhnor9: {rotate: -45}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5dfDr.framer-ar0370, .framer-5dfDr .framer-ar0370 { display: block; }", ".framer-5dfDr.framer-di1udv { cursor: pointer; height: 31px; overflow: hidden; position: relative; width: 31px; }", ".framer-5dfDr .framer-33hnex { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: 6px; width: 27px; }", ".framer-5dfDr .framer-1nseu5l { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: calc(51.61290322580647% - 2px / 2); width: 27px; }", ".framer-5dfDr .framer-12iifpy { bottom: 5px; flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; width: 27px; }", ".framer-5dfDr.framer-v-nuzcrt.framer-di1udv { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 31px); }", ".framer-5dfDr.framer-v-nuzcrt .framer-33hnex { top: calc(51.61290322580647% - 2px / 2); }", ".framer-5dfDr.framer-v-nuzcrt .framer-12iifpy { bottom: unset; top: calc(51.61290322580647% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"luKrhnor9":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"W41POyFF1":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerVX0O6J2r3: React.ComponentType<Props> = withCSS(Component, css, "framer-5dfDr") as typeof Component;
export default FramerVX0O6J2r3;

FramerVX0O6J2r3.displayName = "Hamburger Mnu";

FramerVX0O6J2r3.defaultProps = {height: 31, width: 31};

addPropertyControls(FramerVX0O6J2r3, {variant: {options: ["yLlubh3uH", "luKrhnor9"], optionTitles: ["closed", "open"], title: "Variant", type: ControlType.Enum}, W41POyFF1: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerVX0O6J2r3, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})